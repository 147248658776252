//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters,mapActions } from 'vuex';
import { routerAsync } from '@/app-module';
import AccessoryFormModal from '@/modules/accessory/components/accessory-form-modal.vue';

export default {
  name: 'app-accessory-list-card',
  props: ['rows'],

  components: {
    [AccessoryFormModal.name]: AccessoryFormModal,
  },

  data() {
    return {
      selectedID: '',
      dialogVisible: false,
      modalVisible: false,
      deleteDialogVisible:false,
      confirm:false,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'accessory/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({doDestroy:'accessory/destroy/doDestroy'}),
    closeDialog(){
      this.deleteDialogVisible = false
      this.confirm = false
      this.selectedID = ''
    },
    doDelete(id){
      this.selectedID = id
      this.deleteDialogVisible = true
      this.confirm = true
    },
    deleteItem(id){
      this.doDestroy(id)
    },
    doExport(id) {
      routerAsync().push(`/accessory/${id}/export`);
    },
    doEdit(id) {
      this.selectedID = id
      this.doOpenModal();
    },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      this.selectedID = ''
      setTimeout(() => {
        this.modalVisible = false;
      }, 1000);
    },
  },
};
