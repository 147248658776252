//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccessoryForm from '@/modules/accessory/components/accessory-form';
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';

// import Errors from '@/shared/error/errors';
// import { AccessoryService } from '@/modules/accessory/accessory-service';

export default {
  name: 'app-accessory-form-modal',

  props: ['visible', 'id'],

  components: {
    [AccessoryForm.name]: AccessoryForm,
  },

  data() {
    return {
      successDialog: false,
      // record: null,
      // saveLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      record: 'accessory/form/record',
      findLoading: 'accessory/form/findLoading',
      saveLoading: 'accessory/form/saveLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    title() {
      return i18n('entities.accessory.new.title');
    },
    isEditing() {
      return !!this.id;
    },
  },
  
  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  // },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'accessory/form/doFind',
      doNew: 'accessory/form/doNew',
      doUpdate: 'accessory/form/doUpdate',
      doCreate: 'accessory/form/doCreate',
    }),
    doCancel() {
      this.dialogVisible = false;
    },

    // async doSubmit(payload) {
    //   try {
    //     
    //     this.saveLoading = true;
    //     const { id } = await AccessoryService.create(
    //       payload.values,
    //     );
    //     // const record = await AccessoryService.find(id);
    //     // this.$emit('success', record);
    //     this.$emit('close');
    //   } catch (error) {
    //     Errors.handle(error);
    //   } finally {
    //     this.saveLoading = false;
    //   }
    // },

    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
        this.$emit('close');
      } else {
        const isCreated = await this.doCreate(payload.values);
        this.$emit('close');
        if (isCreated) {
          this.successDialog = true
        }
      }
    },
  },
};
