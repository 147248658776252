//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccessoryListCard from '@/modules/accessory/components/accessory-list-card.vue';
import AccessoryListTable from '@/modules/accessory/components/accessory-list-table.vue';
import AccessoryFormModal from '@/modules/accessory/components/accessory-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { AccessoryPermissions } from '@/modules/accessory/accessory-permissions';
import firebase from 'firebase/app';
import 'firebase/firestore'
import i18n from '@/vueI18n'

export default {
  name: 'app-accessory-list-page',

  components: {
    [AccessoryListTable.name]: AccessoryListTable,
    [AccessoryListCard.name]: AccessoryListCard,
    [AccessoryFormModal.name]: AccessoryFormModal,
  },
  data() {
    return {
      isListView: false,
      dialogVisible: false,
      modalVisible: false,
      unsubscribe:undefined,
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      rows: 'accessory/list/rows',
      count: 'accessory/list/count',
      loading: 'accessory/list/loading',
    }),
    hasPermissionToCreate() {
      return new AccessoryPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },

  async created() {
    this.unsubscribe = await firebase.firestore().collection('accessory').onSnapshot(async querySnapshot => {
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.doFilterInBackground();
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  async mounted() {
    await this.doFilter();
  },
   watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.accessories') 
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'accessory/list/doFetch',
      doFetchInBackground: 'accessory/list/doFetchInBackground',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },

    displaySwitch(view) {
      switch (view) {
        case 'card':
          this.isListView = false
          break;
        case 'list':
          this.isListView = true
          break;
        default:
          break;
      }
    },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 1000);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },

  },

};
